<script lang="ts" setup>
const { t } = useT();

const props = defineProps<{
	itemSection: number;
	hideOnLeave?: boolean;
}>();

const { pageData, lastGames } = useHomePage();
const isGuest = useIsGuest();
const { select } = useGamesState();

const games = computed(() => pageData.value?.schemeSections?.[props.itemSection] || []);
const isPrerenderedComponent = (count: number) => count <= 2;

const getDataTid = (index?: number) => {
	if (index === 0) {
		return `slider-first-item`;
	}
	return null;
};

const gameSectionHeight = {
	transparent_logo: 140,
	slider_vertical: 214,
	"top10-fwl": 168,
	slider_horizontal: 235,
	slider_horizontal_small: 121
};

const skeletonHeight = ({ type, rows = 1 }: { type?: keyof typeof gameSectionHeight; rows?: number }) => {
	const headerHeight = 49; // default header height of section

	return `${(type ? gameSectionHeight[type] : 140) * rows + headerHeight}px`;
};
</script>
<template>
	<template v-for="(game, index) in games" :key="index">
		<template v-if="game?.slug === 'last'">
			<MHomeSectionWrapper
				v-if="!isGuest && lastGames?.length"
				:title="t('Recently Played')"
				icon="home/recent"
				category="favorites"
			>
				<MGameType
					v-for="(item, num) in lastGames"
					:key="item.id"
					:game="item"
					:indexGame="num"
					:data-tid="getDataTid(num)"
				/>
			</MHomeSectionWrapper>
		</template>

		<OLazyComponentNew
			v-else-if="
				pageData?.games[camelCase(game?.slug ?? '')]?.length && pageData?.games[camelCase(game?.slug ?? '')]?.length > 0
			"
			:id="`home-game-${game?.slug}`"
			:prerender="itemSection === 0 ? isPrerenderedComponent(index) : false"
			:hideOnLeave="hideOnLeave"
			:height="skeletonHeight({ type: game?.type_component, rows: !game?.row_count ? 1 : game?.row_count })"
		>
			<MHomeSectionWrapper
				:title="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.title || ''"
				:icon="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.logo || ''"
				:category="game?.slug"
				:row="!game?.row_count ? 1 : game?.row_count"
				isAdminIcon
			>
				<MGameType
					v-for="(item, num) in select(pageData?.games[camelCase(game?.slug ?? '')])"
					:key="item.id"
					:game="item"
					:type="game?.type_component"
					:indexGame="num"
					:data-tid="getDataTid(num)"
				/>
			</MHomeSectionWrapper>
		</OLazyComponentNew>
	</template>
</template>

<style lang="scss" scoped>
div.list-items-wrapper {
	padding: 24px 0;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: 0 16px;
	}
}
</style>
